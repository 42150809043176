<template>
  <div class="">
    <v-form ref="form" v-model="formIsValid">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="name"
            :label="$t('Common.product_name')"
            placeholder="For example, Cheese Pizza"
            color="takein"
            required
            :rules="[rules.nameLength]"
            :counter="nameLength"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-currency-field
            :label="$t('Common.price_per_unit')"
            v-bind="currency_config"
            color="takein"
            v-model="price"
            required
            :rules="[rules.positivePrice]"
          ></v-currency-field>
          <!-- <v-text-field
          :label="$t('Common.price_per_unit')"
          :hide-details="true"
          color="takein"
          ></v-text-field>-->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-combobox
            v-model="selectedProductCategory"
            :items="productCategories"
            :label="$t('Common.product_category')"
            :hide-details="true"
            color="takein"
            class="mb-4"
          ></v-combobox>
        </v-col>
        <v-col cols="12" md="6" class="px-3" v-if="menus && menus.length > 0">
          <v-combobox
            v-model="selectedMenus"
            :items="menus"
            :hide-details="true"
            :label="menuLabel"
            color="takein"
            item-text="name"
            item-value="id"
            multiple
            return-object
            :disabled="isEmpty(menus)"
          ></v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12">
          <v-textarea
            color="takein"
            :label="$t('Common.product_description')"
            :counter="descLength"
            v-model="description"
            rows="2"
            :rules="[rules.descLength]"
            :no-resize="true"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" sm="12" md="4">
          <v-checkbox
            color="takein"
            :hide-details="true"
            v-model="productInventoryUnlimited"
            :label="$t('Common.product_inventory_unlimited')"
            dense
            class="mt-1"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-if="!productInventoryUnlimited"
            :label="$t('Common.inventory_available')"
            color="takein"
            v-model="amountLeft"
            :rules="[rules.positive]"
            type="number"
            placeholder="Units"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            :label="$t('Common.product_leadtime')"
            v-model="productLeadTime"
            color="takein"
            :rules="[rules.positive]"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-btn
            color="takein"
            :ripple="false"
            depressed
            class="mr-4 white--text"
            @click="nextStep()"
            :disabled="!dishIsValid"
            >{{ $t('Common.save_and_continue') }}</v-btn
          >
        </v-col>

        <v-col cols="12" sm="12" md="4">
          <v-btn
            depressed
            :ripple="false"
            to="/profile/wizard/menus/products_sub/list_products"
            >{{ $t('Common.cancel') }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['uid', 'menus', 'product'],
  components: {},
  data() {
    return {
      selectedProductCategory: null,
      productInventoryUnlimited: true,
      currency_config: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
        masked: false,
        allowBlank: true,
        min: 0.0,
        max: Number.MAX_SAFE_INTEGER
      },
      formIsValid: false,
      nameLengthMin: 5,
      nameLength: 50,
      descLength: 300,
      name: '',
      id: null,
      description: '',
      price: 0.0,
      amountLeft: 0,
      productLeadTime: 10,
      selectedMenus: [],
      menuLabel: null,
      rules: {
        positive: value => {
          let val = value ? value.toString() : ''
          val = val.replace(/,/g, '')
          return Number(val) >= 0 || 'Value should be greater than zero.'
        },
        nameLength: value => {
          if (!value) return false
          return (
            (value.length <= this.nameLength &&
              value.length >= this.nameLengthMin) ||
            `Name must be between ${this.nameLength} and ${this.nameLength}characters.`
          )
        },
        descLength: value => {
          return value.length <= this.descLength || 'Characters exceeded.'
        },
        positivePrice: value => {
          let val = value ? value.toString() : ''
          val = val.replace(/,/g, '')
          return Number(val) > 0 || 'Value should be greater than zero.'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      foodCategories: 'foodCategories'
    }),
    productCategories() {
      if (this.foodCategories) {
        return this.foodCategories.map(c => c.name)
      }
    },
    dishIsValid() {
      // business logic
      return (
        this.name &&
        this.name.length >= this.nameLengthMin &&
        this.name.length <= this.nameLength &&
        this.price &&
        this.price > 0 &&
        ((this.amountLeft && this.amountLeft > 0) ||
          this.productInventoryUnlimited) &&
        this.description.length <= this.descLength &&
        this.productLeadTime >= 0
      )
    }
  },
  beforeMount() {
    this.copyProduct()
  },
  async mounted() {
    if (!this.foodCategories || this.foodCategories.length == 0) {
      await this.$store.dispatch('loadFoodTypes')
    }
  },
  watch: {
    product() {
      this.copyProduct()
    }
  },
  methods: {
    isEmpty(items) {
      return !items || items.length == 0
    },
    copyProduct() {
      if (this.product) {
        this.id = this.product.id
        this.name = this.product.name
        this.price = this.product.price
        this.description = this.product.description || ''
        this.productInventoryUnlimited =
          this.product.amountLeft === -1 || !this.product.amountLeft
            ? true
            : false
        this.amountLeft =
          this.product.amountLeft === -1 ? 0 : this.product.amountLeft
        this.selectedProductCategory = this.product.categories
        this.productLeadTime = this.product.leadTime || 10

        const menuItems = this.menus.filter(menu =>
          menu.items.includes(this.product.id)
        )
        this.selectedMenus = menuItems || []
      } else {
        this.id = null
        this.name = null
        this.price = 0.0
        this.description = ''
        this.productInventoryUnlimited = true
        this.amountLeft = -1
        this.productLeadTime = 10
        this.selectedMenus = []
      }
      if (this.isEmpty(this.menus)) {
        this.menuLabel = this.$t('Common.no_menus_found')
      } else {
        this.menuLabel = this.$t('Common.add_product_to_menu')
      }
    },
    nextStep() {
      if (this.dishIsValid) {
        let product = {
          ...this.product,
          price: parseFloat(this.price),
          name: this.name,
          diets: [],
          origins: [],
          ingredients: [],
          description: this.description,
          amountLeft: this.productInventoryUnlimited ? -1 : this.amountLeft,
          categories: this.selectedProductCategory,
          leadTime: this.productLeadTime,
          uid: this.uid
        }
        this.$store.dispatch('saveDish', { food: product }).then(id => {
          product.id = id
          this.id = id

          if (this.selectedMenus.length > 0) {
            const selectedMenuIds = this.selectedMenus.map(x => x.id)
            this.menus.forEach(menu => {
              // if one of the selected menu is this menu and it does not include the product
              if (
                selectedMenuIds.includes(menu.id) &&
                !menu.items.includes(id)
              ) {
                menu.items.push(id)
              }
              // if user removed the product from menu
              if (
                !selectedMenuIds.includes(menu.id) &&
                menu.items.includes(id)
              ) {
                let index = menu.items.indexOf(id)
                if (index > -1) {
                  menu.items.splice(index, 1)
                }
              }
            })
            this.$store.dispatch('saveMenus', {
              menu: this.selectedMenus,
              uid: this.uid
            })
          }
          this.$emit('nextStep', product)
        })
      }
    },
    loadDish() {}
  }
}
</script>
<style>
.shadow {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 4px 8px rgba(0, 0, 0, 0.04);
}
</style>
